import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import Layout from "@utils/layout"
import SEO from "@utils/seo"

import { Section, Container, Flex, Column } from "@atoms/layout"
import { Heading } from "@atoms/typography"
import CardPost from "@molecules/cards/post"
import NavBlog from "@molecules/navBlog"
import Pagination from "@molecules/pagination"

const CategoryTemplate = ({ data, pageContext }) => {
  const { posts } = data.allDatoCmsPost

  return (
    <Layout>
      <SEO title={pageContext.title} />
      <div data-datocms-noindex>
        <NavBlog />
        <Section xlTop md>
          <Heading level="sh" html="h6" strong lightGray>
            Category:
          </Heading>
          <Heading level="h1" html="h1">
            {pageContext.title}
          </Heading>
        </Section>
        <Section light lg smTop>
          <Container xxs>
            <Heading html="h6" level="sh" strong lightGray>
              Recent Posts
            </Heading>
          </Container>
          <Container>
            <Flex>
              {posts.map((post, index) => (
                <PostColumn cards key={index}>
                  <CardPost post={post} />
                </PostColumn>
              ))}
            </Flex>
          </Container>
          <Container smTop>
            <Pagination pageContext={pageContext} />
          </Container>
        </Section>
      </div>
    </Layout>
  )
}

export default CategoryTemplate

export const fetchCategory = graphql`
  query fetchCategory($skip: Int!, $limit: Int!, $cat: String) {
    allDatoCmsPost(
      limit: $limit
      skip: $skip
      sort: { fields: date, order: DESC }
      filter: {
        category: { id: { eq: $cat } }
        meta: { status: { in: ["published", "updated"] } }
      }
    ) {
      posts: nodes {
        author {
          name
          avatar {
            alt
            title
            gatsbyImageData(
              width: 56
              layout: CONSTRAINED
              placeholder: BLURRED
              forceBlurhash: false
              imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
            )
          }
        }
        category {
          title
          slug
        }
        content
        featuredImage {
          alt
          title
          gatsbyImageData(
            width: 480
            layout: CONSTRAINED
            placeholder: BLURRED
            forceBlurhash: false
            imgixParams: { fm: "webp, png", auto: "compress", q: 90 }
          )
        }
        id
        slug
        title
        date
        meta {
          firstPublishedAt
        }
      }
    }
  }
`

const PostColumn = styled(Column)`
  padding-bottom: var(--spacing--sm);
`
